
import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class EnvironmentService {

    private loaded = new BehaviorSubject<boolean>(false);
    isLoaded$ = this.loaded.asObservable();

    private set isLoaded(value: boolean) {
        this.loaded.next(value);
    }

    private get isLoaded(): boolean {
        return this.loaded.getValue();
    }

    constructor(private route: ActivatedRoute, private router: Router, private httpClient: HttpClient) {
        if (localStorage.getItem('apiUrl') && localStorage.getItem('apiUrl').length > 0) {
            environment.apiUrl = (localStorage.getItem('apiUrl'));
        }
        if (localStorage.getItem('hostUrl') && localStorage.getItem('hostUrl').length > 0) {
            environment.hostUrl = (localStorage.getItem('hostUrl'));
        }

        this.getApiHost().subscribe((data) => {
            if (data && data.apiUrl) {
                environment.apiUrl = 'https://' + data.apiUrl + '/';
                localStorage.setItem('apiUrl', JSON.stringify(environment.apiUrl));
            }
            if (data && data.hostUrl) {
                environment.hostUrl = 'https://' + data.hostUrl + '/';
                localStorage.setItem('hostUrl', JSON.stringify(environment.hostUrl));
            }
            this.isLoaded = true;
        }, error => {
            this.isLoaded = true;
        });
    }

    private getApiHost(): Observable<any> {
        return this.httpClient.get('api.json');
    }



}
