import {Component, OnInit} from '@angular/core';
import {AdminUser} from '../models/admin-user';
import {Router} from '@angular/router';
import {DataService} from '../services/data.service';

@Component({
    selector: 'app-admin-user-new',
    templateUrl: './admin-user-new.component.html',
    styleUrls: ['./admin-user-new.component.css']
})
export class AdminUserNewComponent implements OnInit {

    user = new AdminUser();
    errorMessage = '';

    repeatPassword: string;

    constructor(private dataService: DataService, private router: Router) {
    }

    create() {
        if (this.user.username.length === 0) {
            this.errorMessage = 'Bitte geben Sie einen Benutzernamen an.';
        } else if (this.user.password.length < 8) {
            this.errorMessage = 'Das Passwort muss mindestens 8 Zeichen lang sein.';
            // tslint:disable-next-line:triple-equals
        } else if (this.user.password !== this.repeatPassword) {
            this.errorMessage = 'Die Passwörter stimmen nicht überein.';
        } else {
            this.errorMessage = '';
            this.dataService.createAdminUser(this.user).subscribe(data => {
                this.router.navigate(['/admin/settings']);
            });
        }
    }

    ngOnInit() {
    }

}
