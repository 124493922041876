import {Router} from '@angular/router';

export class AdminUser {
    id: number;
    username = '';
    password = '';


    constructor(id?: number, username?: string) {
        this.id = id;
        this.username = username;
    }
}
