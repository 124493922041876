export class Category {
    id: number;
    label: string;
    briefingId: number;


    constructor(id?: number, label?: string, briefingId?: number) {
        this.id = id;
        this.label = label;
        this.briefingId = briefingId;
    }
}
