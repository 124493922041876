import {Component, OnInit} from '@angular/core';
import {Question} from '../models/question';
import {Category} from '../models/category';
import {Briefing} from '../models/briefing';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../services/data.service';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-admin-questions-list',
    templateUrl: './admin-questions-list.component.html',
    styleUrls: ['./admin-questions-list.component.css']
})
export class AdminQuestionsListComponent implements OnInit {

    briefings: Briefing[] = null;

    selectedBriefing: Briefing = null;

    categories: Category[] = null;

    selectedCategory: Category = null;

    questions: Question[] = null;

    constructor(private dataService: DataService, private router: Router, private route: ActivatedRoute) {

    }

    delete(question: Question) {
        if (confirm('Möchten Sie die Frage ' + question.label + ' wirklich löschen?').valueOf()) {
            this.dataService.deleteQuestion(question).subscribe(data => {
                this.dataService.getQuestions(this.selectedCategory).subscribe(data1 => {
                    const currentUserSubject1 = new BehaviorSubject<Question[]>(data1);
                    this.questions = currentUserSubject1.value;
                });
            });
        }
    }



    ngOnInit(briefing?: Briefing, category?: Category) {
        let briefingId;
        let categoryId;
        if (briefing) {
            briefingId = briefing.id;
        } else {
            briefingId = this.route.snapshot.paramMap.get('briefingId');
        }
        if (category) {
            categoryId = category.id;
        } else {
            categoryId = this.route.snapshot.paramMap.get('categoryId');
        }

        this.dataService.getBriefings().subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Briefing[]>(data);
            this.briefings = currentUserSubject.value;
        });

        if (briefingId) {
            this.dataService.getBriefing(+briefingId).subscribe(data => {
                const currentUserSubject = new BehaviorSubject<Briefing>(data);
                this.selectedBriefing = currentUserSubject.value;

                this.dataService.getCategories(this.selectedBriefing).subscribe(data1 => {
                    const currentUserSubject1 = new BehaviorSubject<Category[]>(data1);
                    this.categories = currentUserSubject1.value;
                });
            });
        }
        if (categoryId) {
            this.dataService.getCategory(+categoryId).subscribe(data => {
                const currentUserSubject = new BehaviorSubject<Category>(data);
                this.selectedCategory = currentUserSubject.value;

                this.dataService.getQuestions(this.selectedCategory).subscribe(data1 => {
                    const currentUserSubject1 = new BehaviorSubject<Question[]>(data1);
                    this.questions = currentUserSubject1.value;
                });
            });
        }
    }

    selectBriefing(briefing: Briefing) {
        this.router.navigate(['/admin/questions/list/' + briefing.id]);
        this.ngOnInit(briefing);
    }

    selectCategory(category: Category) {
        this.router.navigate(['/admin/questions/list/' + this.selectedBriefing.id + '/' + category.id]);
        this.ngOnInit(this.selectedBriefing, category);
    }
}
