import { Component, OnInit } from '@angular/core';
import { Global } from '../models/global';
import {DataService} from '../services/data.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  constructor(private dataService: DataService) { }

  ngOnInit() {
  }

  public logoutAdminUser() {
    Global.logoutAdminUser();
  }

  isAdminLoggedIn(): boolean {
    return Global.getAdminUser() != null;
  }

  public cancelBriefing() {
    Global.logoutUser();
  }

  isBriefingRunning(): boolean {
    return Global.getUser() != null;
  }

}
