import {Component, OnInit} from '@angular/core';
import {DataService} from '../services/data.service';
import {Global} from '../models/global';
import {Router} from '@angular/router';


@Component({
    selector: 'app-admin-dashboard',
    templateUrl: './admin-dashboard.component.html',
    styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {

    constructor(private dataService: DataService, private router: Router) {
    }

    ngOnInit() {
        // check if user is authorized
        this.dataService.isAuthorizedAdmin().subscribe(data => {}, error => {
            // user is not authorized
            // logout user
            Global.logoutAdminUser();
            this.router.navigate(['login']);
        });
    }

}
