import {Component, OnInit} from '@angular/core';
import {AdminUser} from '../models/admin-user';
import {Router} from '@angular/router';
import {DataService} from '../services/data.service';
import {Global} from '../models/global';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'app-admin-login',
    templateUrl: './admin-login.component.html',
    styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {

    user: AdminUser = new AdminUser();

    errorMessage = '';

    constructor(private dataService: DataService, private router: Router) {
    }

    ngOnInit() {
    }


    login() {
        this.dataService.loginAdminUser(this.user).subscribe(data => {
            if (!data.token) {
                this.errorMessage = 'Bitte überprüfen Sie Ihre Zugangsdaten.';
            } else {
                Global.setAdminApiToken(data.token);
                Global.setAdminUser(this.user);
                this.router.navigate(['/admin']);
            }
        });

    }

}
