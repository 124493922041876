import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {User} from '../models/user';
import {BriefingStep, Global} from '../models/global';
import {DataService} from '../services/data.service';
import {BehaviorSubject} from "rxjs";
import {Settings} from "../models/settings";

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.css']
})


export class WelcomeComponent implements OnInit {

    user: User;

    errorMessage = '';

    personForm;
    dsgvoAccepted: boolean;

    createUser(form) {
        if (form.valid) {
            this.errorMessage = '';

            this.dataService.createUser(this.user).subscribe(data => {
                this.user.userId = +data.id;
                Global.setApiToken(data.token);
                Global.setUser(this.user);
                this.dataService.startBriefing(+this.route.snapshot.paramMap.get('userType'), Global.getUser()).subscribe(d => {
                    Global.setBriefingStep(BriefingStep.Briefing);
                    this.router.navigate(['/briefing']);
                });
            });
        } else {
            this.errorMessage = 'Bitte überprüfen Sie Ihre Eingaben. Alle Felder müssen ausgefüllt werden.';
        }

    }

    constructor(private dataService: DataService, private router: Router, private route: ActivatedRoute) {
        this.user = new User();
    }

    ngOnInit() {
        this.dataService.getSettings().subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Settings>(data);
            this.dataService._settings = currentUserSubject.value;
            if(this.dataService._settings.heading1 != null){
                localStorage.setItem('heading1', this.dataService._settings.heading1);
            }
            if(this.dataService._settings.heading2 != null){
                localStorage.setItem('heading2', this.dataService._settings.heading2);
            }
            if(this.dataService._settings.text != null){
                localStorage.setItem('text', this.dataService._settings.text);
            }
        });
        this.user.userType = +this.route.snapshot.paramMap.get('userType');
    }


}
