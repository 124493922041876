import {Component, OnInit} from '@angular/core';
import {DataService} from '../services/data.service';
import {Global} from '../models/global';
import {Router} from '@angular/router';

@Component({
    selector: 'app-admin-nav',
    templateUrl: './admin-nav.component.html',
    styleUrls: ['./admin-nav.component.css']
})
export class AdminNavComponent implements OnInit {

    constructor(private dataService: DataService, private router: Router) {
        this.dataService.isAuthorizedAdmin().subscribe((data) => {
            if (data && data.status === 401) {
                Global.logoutAdminUser();
                this.router.navigate(['/login']);
            }
        }, error => {
            if (error.status === 401) {
                Global.logoutAdminUser();
                this.router.navigate(['/login']);
            }
        });
    }

    ngOnInit() {
    }

}
