import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {BriefingStep, Global} from '../models/global';

@Injectable({providedIn: 'root'})
export class BriefingStepGuard implements CanActivate {

    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return true;
        const url = '/' + state.url.split('/')[1];

        if (!Global.getUser()) {
            Global.setBriefingStep(BriefingStep.Home);
        }

        if (state.url === Global.getBriefingStep().toString()) {
            return true;
        }

        if (url === '/welcome') {
            if (Global.getBriefingStep() === BriefingStep.Home) {
                Global.setBriefingStep(BriefingStep.Welcome);
                return true;
            } else if (Global.getBriefingStep() === BriefingStep.Welcome) {
                return true;
            }
        }

        if (url === '/' && Global.getBriefingStep() === BriefingStep.Completed) {
            Global.setBriefingStep(BriefingStep.Home);
            Global.logoutUser();
            return true;
        }

        if (Global.getBriefingStep() === BriefingStep.ApprovalNegative && url === BriefingStep.Briefing) {
            Global.setBriefingStep(BriefingStep.Briefing);
            return true;
        }

        if (Global.getBriefingStep() === BriefingStep.Briefing && url === '/briefing') {
            return true;
        }
        this.router.navigate([Global.getBriefingStep()]);
        return false;
    }
}
