import {Component, OnInit} from '@angular/core';
import {DataService} from '../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Briefing} from '../models/briefing';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-admin-questions-settings-edit',
    templateUrl: './admin-questions-settings-edit.component.html',
    styleUrls: ['./admin-questions-settings-edit.component.css']
})
export class AdminQuestionsSettingsEditComponent implements OnInit {

    errorMessage: string;
    message: string;
    briefing: Briefing;
    oldVideoUrl: string;
    oldMarkdown: string[];

    private _markdownFile;
    fileUploading = false;

    get markdownFile() {
        return this._markdownFile;
    }

    set markdownFile(value) {
        console.log(value);
        this._markdownFile = value;
    }

    constructor(private dataService: DataService, private router: Router, private route: ActivatedRoute) {
        const briefingId = this.route.snapshot.paramMap.get('briefingId');
        this.dataService.getBriefing(+briefingId).subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Briefing>(data);
            this.briefing = currentUserSubject.value;
        });
    }

    ngOnInit() {
    }

    update() {
        if (this.briefing.label.length == 0 || (this.briefing.videoUrl == null && this.briefing.markdown == null) || this.briefing.questionCount < 1 || this.briefing.positiveQuantifier < 0 || this.briefing.positiveQuantifier > 1) {
            this.errorMessage = 'Bitte überprüfen Sie Ihre Eingaben.';
        } else {
            this.dataService.updateBriefing(this.briefing).subscribe(data => {
                this.router.navigate(['/admin/questions/settings']);
            });
        }
    }

    get positiveQuantifier() {
        return this.briefing.positiveQuantifier * 100;
    }

    set positiveQuantifier(value) {
        this.briefing.positiveQuantifier = value / 100;
    }

    selectMarkdown() {
        if (this.oldMarkdown != null) {
            this.briefing.markdown = this.oldMarkdown;
        } else {
            this.briefing.markdown = [''];
        }
        this.oldVideoUrl = this.briefing.videoUrl;
        this.briefing.videoUrl = null;
    }

    selectYoutube() {
        if (this.oldVideoUrl != null) {
            this.briefing.videoUrl = this.oldVideoUrl;
        } else {
            this.briefing.videoUrl = '';
        }
        this.oldMarkdown = this.briefing.markdown;
        this.briefing.markdown = null;
    }


    fileUpload(files: FileList) {
        this.fileUploading = true;
        const file: File = files[0];
        console.log(file);
        const fr = new FileReader();
        fr.readAsText(file);
        fr.onload = (event) => {
            const filecontent = fr.result.toString();
            this.briefing.markdown = filecontent.split('\n---');
            this.fileUploading = false;
        };
    }
}
