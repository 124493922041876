import {Answer} from './answer';
import {Category} from './category';

export class Question {
    id: number;

    label: string;

    description: string;

    answers: Answer[];

    category: Category;
    isTestprotocoll: boolean;


    constructor(id?: number, label?: string, description?: string, category?: Category,  answers?: Answer[], isTestprotocoll?: boolean) {
        this.id = id;
        this.label = label || '';
        this.description = description;
        this.isTestprotocoll = isTestprotocoll;
        this.answers = answers || [];
        this.category = category || new Category();
    }
}
