import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {NavComponent} from './nav/nav.component';
import {HomeComponent} from './home/home.component';
import {AppRoutingModule} from './app-routing.module';
import {WelcomeComponent} from './welcome/welcome.component';
import {FormsModule} from '@angular/forms';
import {BriefingComponent} from './briefing/briefing.component';
import {QuestionComponent} from './question/question.component';
import {ApprovalComponent} from './approval/approval.component';
import {SignaturePadModule} from 'angular2-signaturepad';
import {CompletedComponent} from './completed/completed.component';
import {AdminLoginComponent} from './admin-login/admin-login.component';
import {AdminDashboardComponent} from './admin-dashboard/admin-dashboard.component';
import {AdminNavComponent} from './admin-nav/admin-nav.component';
import {AdminEvaluationComponent} from './admin-evaluation/admin-evaluation.component';
import {AdminSettingsComponent} from './admin-settings/admin-settings.component';
import {AdminUserEditComponent} from './admin-user-edit/admin-user-edit.component';
import {AdminUserNewComponent} from './admin-user-new/admin-user-new.component';
import {AdminQuestionsComponent} from './admin-questions/admin-questions.component';
import {AdminQuestionsListComponent} from './admin-questions-list/admin-questions-list.component';
import {AdminQuestionsCategoriesComponent} from './admin-questions-categories/admin-questions-categories.component';
import {AdminQuestionsSettingsComponent} from './admin-questions-settings/admin-questions-settings.component';
import {DataService} from './services/data.service';
import {ApprovalNegativeComponent} from './approval-negative/approval-negative.component';
import {AdminQuestionEditComponent} from './admin-question-edit/admin-question-edit.component';
import {AdminQuestionNewComponent} from './admin-question-new/admin-question-new.component';
import {AdminQuestionsCategoriesEditComponent} from './admin-questions-categories-edit/admin-questions-categories-edit.component';
import {AdminQuestionsCategoriesNewComponent} from './admin-questions-categories-new/admin-questions-categories-new.component';
import {AdminQuestionsSettingsEditComponent} from './admin-questions-settings-edit/admin-questions-settings-edit.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {PrivacyComponent} from './privacy/privacy.component';
import {YoutubePlayerService} from './services/youtube-player.service';
import {EmptyBodyInterceptor} from './interceptors/empty-body.interceptor';
import {EnvironmentService} from './services/environment.service';
import {BriefingMarkdownComponent} from './briefing-markdown/briefing-markdown.component';
import {MarkdownModule, MarkdownService} from 'ngx-markdown';

@NgModule({
    declarations: [
        AppComponent,
        NavComponent,
        HomeComponent,
        WelcomeComponent,
        BriefingComponent,
        BriefingMarkdownComponent,
        QuestionComponent,
        ApprovalComponent,
        CompletedComponent,
        AdminLoginComponent,
        AdminDashboardComponent,
        AdminNavComponent,
        AdminEvaluationComponent,
        AdminSettingsComponent,
        AdminUserEditComponent,
        AdminUserNewComponent,
        AdminQuestionsComponent,
        AdminQuestionsListComponent,
        AdminQuestionsCategoriesComponent,
        AdminQuestionsSettingsComponent,
        ApprovalNegativeComponent,
        AdminQuestionEditComponent,
        AdminQuestionNewComponent,
        AdminQuestionsCategoriesEditComponent,
        AdminQuestionsCategoriesNewComponent,
        AdminQuestionsSettingsEditComponent,
        PrivacyComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        SignaturePadModule,
        HttpClientModule,
        MarkdownModule.forRoot()
    ],
    providers: [EnvironmentService, {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        }, {
            provide: HTTP_INTERCEPTORS,
            useClass: EmptyBodyInterceptor,
            multi: true,
        }, YoutubePlayerService, MarkdownService, DataService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
