import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {WelcomeComponent} from './welcome/welcome.component';
import {BriefingComponent} from './briefing/briefing.component';
import {QuestionComponent} from './question/question.component';
import {ApprovalComponent} from './approval/approval.component';
import {CompletedComponent} from './completed/completed.component';
import {AdminLoginComponent} from './admin-login/admin-login.component';
import {AdminDashboardComponent} from './admin-dashboard/admin-dashboard.component';
import {AdminEvaluationComponent} from './admin-evaluation/admin-evaluation.component';
import {AdminSettingsComponent} from './admin-settings/admin-settings.component';
import {AdminUserEditComponent} from './admin-user-edit/admin-user-edit.component';
import {AdminUserNewComponent} from './admin-user-new/admin-user-new.component';
import {AdminQuestionsComponent} from './admin-questions/admin-questions.component';
import {AdminQuestionsListComponent} from './admin-questions-list/admin-questions-list.component';
import {AdminQuestionsSettingsComponent} from './admin-questions-settings/admin-questions-settings.component';
import {ApprovalNegativeComponent} from './approval-negative/approval-negative.component';
import {BriefingStepGuard} from './guards/briefing-step.guard';
import {LoginGuard} from './guards/login.guard';
import {AdminAuthGuard} from './guards/admin-auth.guard';
import {AdminQuestionEditComponent} from './admin-question-edit/admin-question-edit.component';
import {AdminQuestionNewComponent} from './admin-question-new/admin-question-new.component';
import {AdminQuestionsCategoriesComponent} from './admin-questions-categories/admin-questions-categories.component';
import {AdminQuestionsCategoriesEditComponent} from './admin-questions-categories-edit/admin-questions-categories-edit.component';
import {AdminQuestionsCategoriesNewComponent} from './admin-questions-categories-new/admin-questions-categories-new.component';
import {AdminQuestionsSettingsEditComponent} from './admin-questions-settings-edit/admin-questions-settings-edit.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {BriefingMarkdownComponent} from './briefing-markdown/briefing-markdown.component';


const routes: Routes = [
    {path: '', component: HomeComponent, canActivate: [BriefingStepGuard]},
    {path: 'privacy', component: PrivacyComponent },
    {path: 'welcome', component: HomeComponent, canActivate: [BriefingStepGuard]},
    {path: 'welcome/:userType', component: WelcomeComponent, canActivate: [BriefingStepGuard]},
    {path: 'briefing', component: BriefingComponent, canActivate: [BriefingStepGuard]},
    {path: 'briefing/md', component: BriefingMarkdownComponent, canActivate: [BriefingStepGuard]},
    {path: 'briefing/md/:step', component: BriefingMarkdownComponent, canActivate: [BriefingStepGuard]},
    {path: 'question', component: QuestionComponent, canActivate: [BriefingStepGuard]},
    {path: 'approval', component: ApprovalComponent, canActivate: [BriefingStepGuard]},
    {path: 'approval/negative', component: ApprovalNegativeComponent, canActivate: [BriefingStepGuard]},
    {path: 'completed', component: CompletedComponent, canActivate: [BriefingStepGuard]},

    {path: 'login', component: AdminLoginComponent, canActivate: [LoginGuard]},
    {path: 'admin', component: AdminDashboardComponent, canActivate: [AdminAuthGuard]},
    {path: 'admin/evaluation', component: AdminEvaluationComponent, canActivate: [AdminAuthGuard]},
    {path: 'admin/settings', component: AdminSettingsComponent, canActivate: [AdminAuthGuard]},
    {path: 'admin/users/new', component: AdminUserNewComponent, canActivate: [AdminAuthGuard]},
    {path: 'admin/users/:id', component: AdminUserEditComponent, canActivate: [AdminAuthGuard]},
    {path: 'admin/questions', component: AdminQuestionsComponent, canActivate: [AdminAuthGuard]},
    {path: 'admin/questions/list', component: AdminQuestionsListComponent, canActivate: [AdminAuthGuard]},
    {path: 'admin/questions/list/:briefingId', component: AdminQuestionsListComponent, canActivate: [AdminAuthGuard]},
    {path: 'admin/questions/list/:briefingId/:categoryId', component: AdminQuestionsListComponent, canActivate: [AdminAuthGuard]},
    {path: 'admin/questions/list/:briefingId/:categoryId/:questionId', component: AdminQuestionEditComponent, canActivate: [AdminAuthGuard]},
    {path: 'admin/questions/new', component: AdminQuestionNewComponent, canActivate: [AdminAuthGuard]},
    {path: 'admin/questions/new/:briefingId', component: AdminQuestionNewComponent, canActivate: [AdminAuthGuard]},
    {path: 'admin/questions/new/:briefingId/:categoryId', component: AdminQuestionNewComponent, canActivate: [AdminAuthGuard]},
    {path: 'admin/questions/categories', component: AdminQuestionsCategoriesComponent, canActivate: [AdminAuthGuard]},
    {path: 'admin/questions/categories/:briefingId', component: AdminQuestionsCategoriesComponent, canActivate: [AdminAuthGuard]},
    {path: 'admin/questions/categories/:briefingId/new', component: AdminQuestionsCategoriesNewComponent, canActivate: [AdminAuthGuard]},
    {path: 'admin/questions/categories/:briefingId/:categoryId', component: AdminQuestionsCategoriesEditComponent, canActivate: [AdminAuthGuard]},
    {path: 'admin/questions/settings', component: AdminQuestionsSettingsComponent, canActivate: [AdminAuthGuard]},
    {path: 'admin/questions/settings/:briefingId', component: AdminQuestionsSettingsEditComponent, canActivate: [AdminAuthGuard]}
];


@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule {

}
