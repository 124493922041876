import {Component, OnInit} from '@angular/core';
import {AuthorizedUser} from '../models/authorized-user';
import {DataService} from '../services/data.service';
import {BehaviorSubject} from 'rxjs';
import {Question} from '../models/question';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-admin-evaluation',
    templateUrl: './admin-evaluation.component.html',
    styleUrls: ['./admin-evaluation.component.css']
})
export class AdminEvaluationComponent implements OnInit {

    allAuthorizedUsers: AuthorizedUser[];
    authorizedUsers: AuthorizedUser[];

    private _search: string;

    constructor(private dataService: DataService, private httpClient: HttpClient) {
        this.dataService.getAuthorizedUsers().subscribe(data => {
            const currentUserSubject = new BehaviorSubject<AuthorizedUser[]>(data);
            this.allAuthorizedUsers = currentUserSubject.value;
            this.authorizedUsers = currentUserSubject.value;
        });
    }

    ngOnInit() {
    }

    get search() {
        return this._search;
    }

    set search(value: string) {
        this._search = value;
        this.authorizedUsers = this.allAuthorizedUsers.filter(au => {
            let found = true;
            this.search.split(' ').forEach(filter => {
                filter = filter.toLowerCase();
                if (!(au.firstName.toLowerCase().indexOf(filter) >= 0 || au.lastName.toLowerCase().indexOf(filter) >= 0 || au.email.toLowerCase().indexOf(filter) >= 0 || au.company.toLowerCase().indexOf(filter) >= 0)) {
                    found = false;
                }
            });

            return found;
        });
    }
}
