import {Component, OnInit} from '@angular/core';
import {Category} from '../models/category';
import {Briefing} from '../models/briefing';
import {DataService} from '../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {Question} from '../models/question';

@Component({
    selector: 'app-admin-questions-categories',
    templateUrl: './admin-questions-categories.component.html',
    styleUrls: ['./admin-questions-categories.component.css']
})
export class AdminQuestionsCategoriesComponent implements OnInit {

    briefings: Briefing[];
    briefing: Briefing;
    categories: Category[] = null;

    constructor(private dataService: DataService, private router: Router, private route: ActivatedRoute) {

    }

    ngOnInit(briefing?: Briefing) {
        let briefingId;
        if (briefing) {
            briefingId = briefing.id;
        } else {
            briefingId = this.route.snapshot.paramMap.get('briefingId');
        }

        if (briefingId) {
            this.dataService.getBriefing(+briefingId).subscribe(data => {
                const currentUserSubject = new BehaviorSubject<Briefing>(data);
                this.briefing = currentUserSubject.value;

                this.dataService.getCategories(this.briefing).subscribe(data1 => {
                    const currentUserSubject1 = new BehaviorSubject<Category[]>(data1);
                    this.categories = currentUserSubject1.value;
                });
            });
        }

        this.dataService.getBriefings().subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Briefing[]>(data);
            this.briefings = currentUserSubject.value;
        });
    }

    delete(category: Category) {
        if (confirm('Möchten Sie die Kategorie ' + category.label + ' inkl. aller dazugehörigen Fragen wirklich löschen?').valueOf()) {
            this.dataService.deleteCategory(category).subscribe(data => {
                this.dataService.getCategories(this.briefing).subscribe(data1 => {
                    const currentUserSubject1 = new BehaviorSubject<Category[]>(data1);
                    this.categories = currentUserSubject1.value;
                });
            });
        }
    }

    selectBriefing(briefing: Briefing) {
        this.router.navigate(['/admin/questions/categories/' + briefing.id]);
        this.ngOnInit(briefing);
    }

}
