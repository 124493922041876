import {Component, OnInit} from '@angular/core';
import {Approval} from '../models/approval';
import {DataService} from '../services/data.service';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';

@Component({
    selector: 'app-approval-negative',
    templateUrl: './approval-negative.component.html',
    styleUrls: ['./approval-negative.component.css']
})
export class ApprovalNegativeComponent implements OnInit {

    private approval: Approval;

    constructor(private dataService: DataService, private router: Router) {
        this.dataService.getApproval().subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Approval>(data);
            this.approval = currentUserSubject.value;
        });
    }

    ngOnInit() {
    }

    restartBriefing() {
        this.dataService.restartBriefing().subscribe(data => {
            this.router.navigate(['/briefing']);
        });
    }

}
