import {Approval} from '../models/approval';
import {AdminUser} from '../models/admin-user';
import {Question} from '../models/question';
import {User} from '../models/user';
import {Briefing} from '../models/briefing';
import {Category} from '../models/category';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Global} from '../models/global';
import {Settings} from '../models/settings';
import {environment} from '../../environments/environment';
import {EnvironmentService} from './environment.service';

@Injectable({
    providedIn: 'root'
})
/* tslint:disable:no-string-literal */
export class DataService {

    public _settings: Settings;
    public _isTestprotocoll = false;

    private bearerToken: string;
    private subscription: Subscription;
    private isLoaded = false;

    public get apiUrl() {
        return environment.apiUrl;
    }

    constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {
        this.subscription = this.environmentService.isLoaded$
            .subscribe(loaded => {
                this.isLoaded = loaded;
            });
        this.bearerToken = Global.getApiToken();
    }


    get settings(): Settings {
        return this._settings;
    }

    set settings(value: Settings) {
        this._settings = value;
    }

    get isTestprotocoll(): boolean {
        return this._isTestprotocoll;
    }

    set isTestprotocoll(value: boolean) {
        this._isTestprotocoll = value;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public getAuthorizedUsers(): Observable<any> {
        const url = this.apiUrl + 'admin/authorizations.php';
        return this.httpClient.get(url);
    }


    public getApproval(): Observable<any> {
        const url = this.apiUrl + 'approval.php';
        return this.httpClient.get(url);
    }

    public createApproval(approval: Approval): Observable<any> {
        const url = this.apiUrl + 'approval.php';
        return this.httpClient.put(url, approval);
    }


    public getQuestions(category: Category): Observable<any> {
        const url = this.apiUrl + 'admin/question.php/byCategory/' + category.id;
        return this.httpClient.get(url);
    }

    // Param: questionId - if null get random Question
    public getQuestion(questionId?: number, isTestprotocoll = false): Observable<any> {
        if (questionId && !isTestprotocoll) {
            const url = this.apiUrl + 'admin/question.php/' + questionId;
            return this.httpClient.get(url);
        } else {
            // get random question
            const url = this.apiUrl + 'question.php/random';
            if (questionId) {
                return this.httpClient.get(url, {params: {questionId: questionId.toString()}});
            }
            return this.httpClient.get(url);
        }
    }

    public submitQuestion(question: Question): Observable<any> {
        const questionId = question.id;

        const checkedAnswers = [];
        let additionalText = '';

        console.log(question);

        // tslint:disable-next-line:only-arrow-functions
        question.answers.forEach(function (answer) {
            if (answer.selected) {
                checkedAnswers.push(answer.id);
            }
            additionalText += answer.id + ':' + answer.selected + ':' + answer.additionalText;
            additionalText += '***';
        });

        const url = this.apiUrl + 'answer.php/submit';
        additionalText = additionalText.substring(0, additionalText.length - 3);
        const body = new FormData();
        body.append('question_id', questionId.toString());
        body.append('answers', JSON.stringify(checkedAnswers));
        body.append('additional_info', additionalText);
        body.append('is_testprotocoll', question.isTestprotocoll.toString());

        return this.httpClient.post(url, body);
    }

    public updateQuestion(question: Question): Observable<any> {
        const url = this.apiUrl + 'admin/question.php/' + question.id;
        return this.httpClient.put(url, question);
    }

    public createQuestion(question: Question): Observable<any> {
        const url = this.apiUrl + 'admin/question.php';
        return this.httpClient.post(url, question);
    }

    public isLastQuestion(): Observable<any> {
        const url = this.apiUrl + 'question.php/finished';
        return this.httpClient.get(url);
    }


    public getBriefings(): Observable<any> {
        const url = this.apiUrl + 'admin/briefing.php';
        return this.httpClient.get(url);
    }

    public getBriefing(briefingId: number): Observable<any> {
        const url = this.apiUrl + 'admin/briefing.php/' + briefingId;
        return this.httpClient.get(url);
    }

    public restartBriefing() {
        const url = this.apiUrl + 'approval.php/restart';
        return this.httpClient.get(url);
    }

    public updateBriefing(briefing: Briefing): Observable<any> {
        const url = this.apiUrl + 'admin/briefing.php/' + briefing.id;
        return this.httpClient.put(url, briefing);
    }

    public getCategories(briefing: Briefing): Observable<any> {
        const url = this.apiUrl + 'admin/category.php/byBriefing/' + briefing.id;
        return this.httpClient.get(url);
    }

    public getQuestionCategories(questionId: number): Observable<any> {
        const url = this.apiUrl + 'category.php/' + questionId;
        return this.httpClient.get(url);
    }

    public getCategory(categoryId: number): Observable<any> {
        const url = this.apiUrl + 'admin/category.php/' + categoryId;
        return this.httpClient.get(url);
    }

    public updateCategory(category: Category): Observable<any> {
        const url = this.apiUrl + 'admin/category.php/' + category.id;
        return this.httpClient.put(url, category);
    }

    public createCategory(category: Category): Observable<any> {
        const url = this.apiUrl + 'admin/category.php';
        return this.httpClient.post(url, category);
    }

    public createUser(user: User): Observable<any> {
        const url = this.apiUrl + 'user.php';

        const body = new FormData();
        body.append('firstName', user.firstName);
        body.append('lastName', user.lastName);
        body.append('email', user.email);
        body.append('company', user.company);

        return this.httpClient.post(url, body);
    }

    public startBriefing(id: number, user: User): Observable<any> {
        const url = this.apiUrl + 'approval.php';

        const body = new FormData();
        body.append('briefing_id', id.toString());
        body.append('user_id', user.userId.toString());

        return this.httpClient.post(url, body);
    }

    public loginAdminUser(adminUser: AdminUser): Observable<any> {

        const url = this.apiUrl + 'admin/user.php/login';

        const body = new FormData();
        body.append('username', adminUser.username);
        body.append('password', adminUser.password);

        return this.httpClient.post(url, body);
    }


    public getAdminUsers(): Observable<any> {
        const url = this.apiUrl + 'admin/user.php';
        return this.httpClient.get(url);
    }

    public getAdminUser(adminUserId: number): Observable<any> {
        const url = this.apiUrl + 'admin/user.php/' + adminUserId;
        return this.httpClient.get(url);
    }

    public updateAdminUser(adminUser: AdminUser): Observable<any> {
        const url = this.apiUrl + 'admin/user.php/' + adminUser.id;
        return this.httpClient.put(url, adminUser);
    }

    public createAdminUser(adminUser: AdminUser): Observable<any> {
        const url = this.apiUrl + 'admin/user.php';
        return this.httpClient.post(url, adminUser);
    }

    public getCurrentUserBriefing(): Observable<any> {
        const url = this.apiUrl + 'briefing.php';
        return this.httpClient.get(url);
    }

    public isAuthorizedAdmin(): Observable<any> {
        const url = this.apiUrl + 'admin/authentication.php';
        return this.httpClient.get(url);
    }

    public deleteQuestion(question: Question): Observable<any> {
        const url = this.apiUrl + 'admin/question.php/' + question.id;
        return this.httpClient.delete(url);
    }

    public deleteCategory(category: Category) {
        const url = this.apiUrl + 'admin/category.php/' + category.id;
        return this.httpClient.delete(url);
    }

    public deleteAdminUser(user: AdminUser): Observable<any> {
        const url = this.apiUrl + 'admin/user.php/' + user.id;
        return this.httpClient.delete(url);
    }

    public createApprovalPDF(approval: Approval): Observable<any> {
        const url = this.apiUrl + 'pdf/create.php/' + approval.id;
        return this.httpClient.get(url);
    }

    public getSettings(): Observable<any> {
        const url = this.apiUrl + 'admin/settings.php';
        return this.httpClient.get(url);
    }

    public updateSettings(settings: Settings) {
        const url = this.apiUrl + 'admin/settings.php';
        return this.httpClient.put(url, settings);
    }
}
