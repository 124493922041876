export class User {
    userId: number;
    firstName = '';
    lastName = '';
    email = '';
    company = '';
    userType: number;

    isExtern(): boolean {
        return this.userType < 3;
    }
}
