import {Component, OnInit} from '@angular/core';
import {User} from '../models/user';
import {BriefingStep, Global} from '../models/global';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../services/data.service';
import {Briefing} from '../models/briefing';
import {BehaviorSubject} from 'rxjs';
import {AdminUser} from '../models/admin-user';
import {DomSanitizer} from '@angular/platform-browser';
import {YoutubePlayerService} from '../services/youtube-player.service';


@Component({
    selector: 'app-briefing',
    templateUrl: './briefing.component.html',
    styleUrls: ['./briefing.component.css']
})
export class BriefingComponent implements OnInit {

    briefing: Briefing;

    user: User;

    constructor(private dataService: DataService, private router: Router, private youtubePlayer: YoutubePlayerService) {
        this.user = Global.getUser();
    }

    ngOnInit() {
        const doc = window.document;
        const playerApi = doc.createElement('script');
        playerApi.type = 'text/javascript';
        playerApi.src = 'https://www.youtube.com/iframe_api';
        doc.body.appendChild(playerApi);

        this.dataService.getCurrentUserBriefing().subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Briefing>(data);
            this.briefing = currentUserSubject.value;
            if (this.briefing.videoUrl) {
                this.youtubePlayer.createPlayer(this.briefing.videoUrl);
            } else {
                this.router.navigate(['/briefing/md']);
            }
        });
    }

    briefingFinished() {
        if (this.youtubePlayer.isFinished()) {
            Global.setBriefingStep(BriefingStep.Questions);
        }
    }


    isVideoFinished(): boolean {
        if (this.youtubePlayer) {
            return this.youtubePlayer.isFinished();
        } else {
            return false;
        }
    }
}




