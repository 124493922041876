import {Component, OnInit} from '@angular/core';
import {Question} from '../models/question';
import {DataService} from '../services/data.service';
import {BriefingStep, Global} from '../models/global';
import {BehaviorSubject} from 'rxjs';
import {Briefing} from '../models/briefing';
import {Router} from '@angular/router';
import {Category} from "../models/category";

@Component({
    selector: 'app-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {

    public question: Question;
    public categories: Category[] = [];

    constructor(private dataService: DataService, private router: Router) {
        if (localStorage.getItem('isTestprotocoll') === '1') {
            this.dataService.getQuestionCategories(4).subscribe(data => {
                data.forEach(obj => {
                    const currentCategorySubject = new BehaviorSubject<Category>(obj);
                    this.categories.push(currentCategorySubject.value);
                });
            });

        } else {
            this.dataService.getQuestion().subscribe(data => {
                const currentUserSubject = new BehaviorSubject<Question>(data);
                this.question = currentUserSubject.value;
            });
        }
    }

    ngOnInit() {

    }

    submit() {
        this.dataService._isTestprotocoll = this.question.isTestprotocoll;
        localStorage.setItem('isTestprotocoll', '' + this.question.isTestprotocoll);
        this.dataService.submitQuestion(this.question).subscribe(data => {
            this.dataService.isLastQuestion().subscribe(last => {
                if (last.toString() === 'true') {
                    Global.setBriefingStep(BriefingStep.Approval);
                    this.router.navigate(['/approval']);
                } else {
                    this.dataService.getQuestion().subscribe(d => {
                        const currentUserSubject = new BehaviorSubject<Question>(d);
                        this.question = currentUserSubject.value;
                    });
                }
            });
        });
    }

    isAnswerChecked() {
        let checked = false;

        if (this.question != null) {
            this.question.answers.forEach(answer => {
                if (answer.selected) {
                    checked = true;
                }
            });
        }
        return checked;
    }

    getQuestion(questionId) {
        this.dataService.getQuestion(questionId, true).subscribe(d => {
            const currentUserSubject = new BehaviorSubject<Question>(d);
            this.question = currentUserSubject.value;
        });
    }

}
