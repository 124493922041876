import {Component, OnInit} from '@angular/core';
import {Briefing} from '../models/briefing';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../services/data.service';
import {Category} from '../models/category';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-admin-question-categories-new',
    templateUrl: './admin-questions-categories-new.component.html',
    styleUrls: ['./admin-questions-categories-new.component.css']
})
export class AdminQuestionsCategoriesNewComponent implements OnInit {

    errorMessage: string;
    message: string;

    briefing: Briefing;
    briefings: Briefing[];

    category: Category = new Category();

    constructor(private dataService: DataService, private router: Router, private route: ActivatedRoute) {
        const briefingId = this.route.snapshot.paramMap.get('briefingId');
        this.category.briefingId = +briefingId;
        this.dataService.getBriefing(+briefingId).subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Briefing>(data);
            this.briefing = currentUserSubject.value;
        });
        this.dataService.getBriefings().subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Briefing[]>(data);
            this.briefings = currentUserSubject.value;
        });
    }

    ngOnInit() {
    }

    get briefingId(): number {
        return this.briefing.id;
    }

    set briefingId(id: number) {
        this.dataService.getBriefing(id).subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Briefing>(data);
            this.briefing = currentUserSubject.value;
        });
        this.category.briefingId = id;
    }

    create() {
        if (!this.category.label || this.category.label.length === 0) {
            this.errorMessage = 'Bitte überprüpfen Sie Ihre Eingaben.';
        } else {
            this.dataService.createCategory(this.category).subscribe(data => {
                this.router.navigate(['/admin/questions/categories/' + this.briefing.id]);
            });
        }
    }


}
