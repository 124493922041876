import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Global} from '../models/global';
import {Router} from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.router.url.startsWith('/admin')) {
            const token = Global.getAdminApiToken();

            // only modify header if token is available, example: login won't have a token
            if (token) {
                req = req.clone({
                    setHeaders: {
                        // 'Content-Type': 'application/json; charset=utf-8',
                        // Accept: 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                });
            }
            return next.handle(req);
        } else {
            const token = Global.getApiToken();

            // only modify header if token is available, example: login won't have a token
            if (token) {
                req = req.clone({
                    setHeaders: {
                        // 'Content-Type': 'application/json; charset=utf-8',
                        // Accept: 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                });
            }
            return next.handle(req);
        }


    }
}
