import {Component, OnInit} from '@angular/core';
import {Global} from '../models/global';
import {DataService} from '../services/data.service';

@Component({
    selector: 'app-completed',
    templateUrl: './completed.component.html',
    styleUrls: ['./completed.component.css']
})
export class CompletedComponent implements OnInit {

    constructor(private dataService: DataService) {
        Global.logoutUser();
    }

    ngOnInit() {

    }

}
