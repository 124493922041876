import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Global} from '../models/global';
import {DataService} from '../services/data.service';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class LoginGuard implements CanActivate {
    constructor(private router: Router, private dataService: DataService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // check if adminuser is already logged in
        if (Global.getAdminUser()) {
            this.router.navigate(['/admin']);
            return false;
        } else {
            return true;
        }
    }
}
