import {Component, OnInit} from '@angular/core';
import {Briefing} from '../models/briefing';
import {DataService} from '../services/data.service';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-admin-questions-settings',
    templateUrl: './admin-questions-settings.component.html',
    styleUrls: ['./admin-questions-settings.component.css']
})
export class AdminQuestionsSettingsComponent implements OnInit {

    briefings: Briefing[];

    constructor(private dataService: DataService) {
        this.dataService.getBriefings().subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Briefing[]>(data);
            this.briefings = currentUserSubject.value;
        });
    }

    ngOnInit() {
    }

}
