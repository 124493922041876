export class Answer {

    id: number;
    label: string;
    selected = false;
    questionId: number;
    additionalText: string;

    constructor(id?: number, label?: string, questionId?: number) {
        this.id = id;
        this.label = label;
        this.questionId = questionId;
    }
}
